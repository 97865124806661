<template>
    <div>
        <h3>Utilities</h3>
        <v-tabs v-model="tab" class="mx-3 mt-3">
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="containsSmsCounter" tag="label" for="unitCounter">
                SMS Unit Calculator
            </v-tab>
        </v-tabs>
        <div class="utility mx-3">
            <v-tabs-items v-model="tab">
                <v-tab-item v-if="containsSmsCounter">
                    <div class="utility-textarea">
                        <TextareaCounted
                            input-name="unitCounter"
                            autosize
                            enableUnicodeStripping
                            smsLimit />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import TextareaCounted from '../components/TextareaCounted.vue';
import { randomHex } from '../utils';

export default {
    name: 'DocumentationUtilities',
    data: () => ({
        tab: null
    }),
    props: [
        'availableUtilities'
    ],
    beforeMount: function() {
        this.tab = Object.keys(this.availableUtilities)[0];
    },
    components: {
        TextareaCounted
    },
    computed: {
        containsSmsCounter: function() {
            return 'smsCounter' in this.availableUtilities;
        }
    },
    methods: {
        randomHex
    }
};
</script>

<style>
.v-window {
    overflow: visible !important;
}

.utility-container {
    margin-top: 10px;
}

.utility-textarea {
    margin-top: 10px;
}
</style>
