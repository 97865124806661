<template>
    <v-navigation-drawer permanent app>
        <v-list-item>
            <v-list-item-content>
                <v-img
                    :src='brand.logo'
                    max-height='65'
                    class="mb-5"
                    contain
                ></v-img>
                <v-list-item-title class='title font-weight-light'>
                    {{brand.shortName}} API Docs
                </v-list-item-title>
                <v-list-item-subtitle class='font-weight-light'>
                    API Version: {{ currentDocs }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class='font-weight-light'>
                    Last Update: {{ lastUpdate }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
            <v-list-group
                v-for='(item, menuIndex) in menuItems'
                :key='item.title'
                v-model='item.active'
                :prepend-icon='item.action'
                no-action
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text='item.title'></v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item
                    v-for='(subItem, subMenuIndex) in item.subMenuItems'
                    :key='subMenuIndex'
                    @click='focusCurrentSubMenuItem(menuIndex, subMenuIndex)'
                    :class="currentChildIndex === subMenuIndex ? 'active': ''"
                    :to='subItem.route'
                >
                    <v-list-item-action v-if="subItem.icon">
                        <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ subItem.request }}
                            {{ subItem.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { buildSidebarMenuItems } from '../utils';
import brand from 'brand/env';

const lastUpdate = [...document.head.children].find(el => el.name === 'builddate').content;

export default {
    name: 'Sidebar',
    data: () => ({
        lastUpdate,
        currentParentIndex: 0,
        currentChildIndex: 0,
        menuItems: [],
        brand,
    }),
    props: [
        'docs',
        'currentDocs',
        'currentPage',
    ],
    methods: {
        focusCurrentSubMenuItem: function(parentIndex, childIndex) {
            this.currentParentIndex = parentIndex;
            this.currentChildIndex = childIndex;
            var elementId = this.menuItems[this.currentParentIndex].subMenuItems[this.currentChildIndex].id;
            var currentElement = document.getElementById(elementId);
            currentElement.scrollIntoView();
        },
    },
    mounted() {
        this.menuItems = buildSidebarMenuItems(this.docs[this.currentDocs], this.currentDocs);
        this.currentParentIndex = this.menuItems.findIndex(item => item.title.toLowerCase() === this.currentPage);
        this.menuItems[this.currentParentIndex].active = true;
        window.addEventListener('scroll', () => {
            var closestElementDistance = Infinity;
            var closestElementId = null;
            this.menuItems[this.currentParentIndex].subMenuItems.forEach(subItem => {
                var currentElement = document.getElementById(subItem.id);
                var distanceFromTop = Math.abs(currentElement.getBoundingClientRect().top);
                if (closestElementDistance > distanceFromTop) {
                    closestElementDistance = distanceFromTop;
                    closestElementId = subItem.id;
                }
            });
            if (this.$router.currentRoute.params.endPoint !== closestElementId) {
                const apiVersion = this.$router.currentRoute.params.apiVersion;
                const resource = this.$router.currentRoute.params.resource;
                this.$router.push(`/${apiVersion}/${resource}/${closestElementId}`);
            }
        });
    },
    destroyed() {
        window.removeEventListener('scroll');
    }
}
</script>
