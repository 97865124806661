<template>
  <span
    class="font-weight-bold accent-4"
    :class="{ 'amber--text': nearLimit, 'red--text': atLimit }">
    {{ smsCount(value, true, max) }}
  </span>
</template>

<script>
import { smsCount, maxChars } from '../utils';
export default {
  name: 'InputCounter',
  props: {
    value: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 0
    },
    includeUnits: {
      type: Boolean,
      default: true
    }
  },
  methods: { smsCount },
  computed: {
    nearLimit() {
      if (!this.value) return false;
      return Array.from(this.value).length / maxChars(this.value) > 0.9 && !this.atLimit;
    },
    atLimit() {
      if (!this.value) return false;
      return Array.from(this.value).length === maxChars(this.value);
    }
  }
};
</script>
