<template>
  <v-app>
    <Sidebar
      :docs='docs'
      :currentDocs='currentDocs'
      :currentPage='currentPage'
    />
    <v-main>
      <v-container
        class="py-8 px-6"
        fluid
      >
        <DocumentationContent
          :docs='docs'
          :currentDocs='currentDocs'
          :currentPage='currentPage'
          :currentEndPoint='currentEndPoint'
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import DocumentationContent from '../components/DocumentationContent.vue';

export default {
  name: 'Documentation',
  data: () => ({
    currentDocs: '',
    currentPage: '',
    currentEndPoint: '',
  }),
  components: {
    Sidebar,
    DocumentationContent
  },
  props: [
    'docs',
  ],
  beforeMount() {
    this.setCurrentDocumentation();
  },
  methods: {
    setCurrentDocumentation: function(params = this.$router.currentRoute.params) {
        this.currentDocs = params.apiVersion;
        this.currentPage = params.resource;
        this.currentEndPoint = params.endPoint;
    }
  },
  watch: {
    $route(to) {
        this.setCurrentDocumentation(to.params, to.hash);
    }
  }
}
</script>
