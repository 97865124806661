<template>
    <v-row v-if="headerTitle && headerDescription">
        <v-col cols='12'>
            <h3>{{ headerTitle }}</h3>
            <p class='mb-0' v-if='!!headerDescription'>{{ headerDescription }}</p>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ItemSubHeader',
    props: [
        'headerTitle',
        'headerDescription',
    ]
}
</script>
