module.exports = {
    name: 'Message2Give',
    shortName: 'M2G',
    company: 'Message2Give',
    logo: './logo-message2give.svg',
    apiUrl: 'https://api.app.message2give.com/2019-01-23/',
    portalUrl: 'https://app.message2give.com/',
    chat: {
        name: 'Chat',
    },
};
