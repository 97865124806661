<template>
    <div class="textarea-counted-container" :class="{ 'autosized' : autosize }">
        <div class="unicode-container" v-if="enableUnicodeStripping">
          <input
            v-model="stripUnicode"
            :id="`strip-${inputName}`"
            type="checkbox"
            @change="onInput(localValue)">
          <label
            class="unicode-checkbox"
            :for="`strip-${inputName}`">
              Replace unicode characters
          </label>
        </div>
        <textarea
          :value="localValue"
          :id="inputName"
          ref="textarea"
          class="textarea-counted"
          :class="fieldClasses"
          :disabled="disabled"
          :maxlength="maxLength"
          :minlength="min"
          :placeholder="placeholder"
          :readonly="readonly"
          :required="required"
          :rows="rows"
          name="textarea"
          @keydown.enter.exact="enterPressed"
          @input="onInput($event.target.value)"/>
        <input-counter
          class="input-counter"
          v-show="localValue && localValue.length && counterEnabled"
          :value="localValue"
          :include-units="includeUnits"
          :max="maxLength"/>
      </div>
</template>
<script>
import InputCounter from './InputCounter.vue';
import { randomHex, maxChars, restrictSmsInput, autosize } from '../utils';
import { unicodeRegExp } from '../config/regex';

export default {
  name: 'TextareaCounted',
  components: {
    InputCounter
  },
  props: {
    smsLimit: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 600,
    },
    min: {
      type: Number,
      default: 0,
    },
    autosize: {
      type: Boolean,
      default: false,
    },
    fieldClasses: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    inputName: {
      type: String,
      default: () => randomHex(5),
    },
    value: {
      type: String,
      default: '',
    },
    inputUpdateInterval: {
      type: Number,
      default: 500
    },
    includeUnits: {
      type: Boolean,
      default: true
    },
    counterEnabled: {
      type: Boolean,
      default: true,
    },
    preventEnter: {
      type: Boolean,
      default: false,
    },
    enableUnicodeStripping: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      localValue: '',
      inputTimeout: null,
      stripUnicode: true,
    }
  },
  computed: {
    maxLength: function() {
      return this.smsLimit ? maxChars(this.localValue) : this.max;
    },
  },
  methods: {
    onInput: function(value) {
      this.localValue = this.stripUnicodeInput(value);
      this.$nextTick(() => this.localAutosize());

      if (this.inputTimeout) {
        clearTimeout(this.inputTimeout);
      }

      this.inputTimeout = setTimeout(() => {
        this.$emit('on-input', this.smsLimit ? restrictSmsInput(this.localValue) : this.localValue);
      }, this.inputUpdateInterval);
    },
    localAutosize: function() {
      if (!this.autosize) return;
      autosize(this.$refs.textarea);
    },
    initialAutosize: function() {
      // $nextTick and Vue updated events dont fire at acceptable times to autosize on show
      if (!this.autosize) return;

      setTimeout(() => {
        this.localAutosize();
        if (this.$refs.textarea.clientHeight === 0) this.initialAutosize();
      }, 10);
    },
    enterPressed: function(evt) {
      if (this.preventEnter) {
        evt.preventDefault();
      }

      this.$emit('enter', this.value);
    },
    stripUnicodeInput: function(value) {
      if (!this.enableUnicodeStripping || !this.stripUnicode) return value;

      [
        { search: /[–—]/g, replace: '-' }, // en/em dash
        { search: /‘/g, replace: "'" }, // left quote
        { search: /’/g, replace: "'" }, // right quote
        { search: /“/g, replace: '"' }, // left double quote
        { search: /”/g, replace: '"' }, // right double quote
        { search: new RegExp('[\u00a0\u2000\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u202f\u205f\u3000\ufeff]', 'g'), replace: ' ' }, // unicode spaces
        { search: new RegExp(unicodeRegExp, 'g'), replace: '#' }, // replace unknowns with #
      ].forEach(replacement => value = value.replace(replacement.search, replacement.replace));

      return value;
    }
  },
  watch: {
    value: function(newVal) {
      this.onInput(newVal);
    }
  },
  mounted: function() {
    this.initialAutosize();
    this.localValue = this.value;
  },
  beforeDestroy: function() {
    if (!this.inputTimeout) return;
    clearTimeout(this.inputTimeout);
  }
};
</script>

<style lang="scss" scoped>
.autosized {
  textarea.textarea-counted {
    overflow-y: hidden;
  }
}

.unicode-container {
  margin-top: 5px;
  margin-bottom: 10px;
}

.unicode-checkbox {
    margin-right: 5px;
}

.textarea-counted {
    box-shadow: none;
    padding: 6px 12px;
    height: 38px;
    border-color: #ced4da;
    color: #495057;
    width: 100%;
    border-style: solid;

    &:focus {
        box-shadow: 0 0 0 3.2px rgba(21, 130, 216, 0.25);
        border-color: #7abdf2;
        outline: none;
    }

    &:disabled {
        background-color: #EFEFF0;
    }
}

.input-counter {
  position: absolute;
  bottom: 8px;
  right: 24px;
  font-size: 0.8em;
  color: #333;
}

.custom-checkbox {
  font-weight: 600;
}

.textarea-counted-container {
  position: relative;
}
</style>
