export default {
    authentication: {
        action: 'mdi-shield-lock',
    },
    messages: {
        action: 'mdi-message-text',
    },
    msns: {
        action: 'mdi-phone',
    },
    members: {
        action: 'mdi-account-multiple'
    },
    transcripts: {
        action: 'mdi-file-document-multiple-outline',
    },
    lists: {
        action: 'mdi-format-list-bulleted',
    },
    statistics: {
        action: 'mdi-chart-line'
    },
    validation: {
        action: 'mdi-checkbox-marked-circle'
    },
    apiUsage: {
        action: 'mdi-code-json'
    }
}
