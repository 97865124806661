<template>
    <v-row>
        <v-col cols='12'>
            <h2 class=''>
                <span :class='getRequestColour(request)'>
                    {{ request }}
                </span>
                {{ title }}
            </h2>
            <h4 class=''>
                {{ endPoint ? '/' : ''}}{{ endPoint }}
            </h4>
        </v-col>
    </v-row>
</template>

<script>
import { getRequestColour } from '../utils';

export default {
    name: 'ItemSubHeader',
    props: [
        'title',
        'request',
        'endPoint',
    ],
    methods: {
        getRequestColour
    }
}
</script>
