import Vue from 'vue';
import VueRouter from 'vue-router';
import Documentation from '../components/Documentation.vue';
// import PageNotFound from '../components/PageNotFound.vue'
import docs from '../config/docs';

Vue.use(VueRouter)

const routes = [
  {
    path: '/:apiVersion/:resource/:endPoint',
    component: Documentation,
    props: { docs },
  },
  {
    path: '*',
    redirect: '/2019-01-23/authentication/apiKey'
  }
]

const router = new VueRouter({
  routes,
})

export default router
