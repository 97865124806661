<template>
    <div>
        <v-row>
            <v-col cols='12'>
                <h1>{{ title }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols='12'>
                <p>{{ description }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'DocumentationHeader',
    props: [
        'title',
        'description'
    ],
}
</script>
