import brand from 'brand/env';
import { apiKey, sessionToken } from '../../appText';

export default {
    authentication: {
      apiKey: {
        requestExample: `$ curl
  -s
  -H 'x-api-key: ${apiKey}'
  -H 'content-type: application/json'
  -d '{
    "to": "61400000000",
    "from": "${brand.name}",
    "content": "Hello, World!",
    "type": "sms",
    "simulated": true,
  }`,
        responseExample: '',
      },
      sessionToken: {
        requestExample: `$ curl
  -s
  -H 'x-token: ${sessionToken}'
  -H 'content-type: application/json'
  -d '{
    "to": "61400000000",
    "from": "${brand.name}",
    "content": "Hello, World!",
    "type": "sms",
    "simulated": true,
  }`,
        responseExample: '',
      },
      basic: {
        requestExample: `$ curl
  -s
  -H 'Authorization: Basic usernamehere:passwordhere'
  -H 'content-type: application/json'
  -d '{
    "to": "61400000000",
    "from": "${brand.name}",
    "content": "Hello, World!",
    "type": "sms",
    "simulated": true,
  }`,
        responseExample: '',
      },
    },
    messages: {
        messagesPost: {
            requestExample: `$ curl
  -s
  -X POST
  -H 'Authorization: Basic usernamehere:passwordhere'
  -H 'content-type: application/json'
  # "simulated" is only used for testing receipts. Remove this property to send messages.
  # "template" is an optional property to overwite message content with a ${brand.name} Template.
  # "fields" is an optional property to set placeholder field values if the "template" property is used.
  -d '{
    "to": "61400000000",
    "from": "${brand.name}",
    "content": "Hello, World!",
    "type": "sms",
    "simulated": true,
    "template": "2389f089734c6ct30494588bf942a370f484331d75edd48370e584a4f07dfd",
    "fields": {
      "fieldNameOne": "Hello",
      "field_name_two": "World",
      "field name 3": "How are you?"
    }
  }'
  ${brand.apiUrl}messages`,
            responseExample: `{
  "simulated" : true,
  "status" : "Message Submitted",
  "messages" : [
      {
          "uri" : "/2019-01-23/messages/defa41de80f0974ef06f78f6058d1424",
          "to" : "61400000000",
          "type" : "sms",
          "receipts" : {},
          "content" : "Hello, World!",
          "created" : "2019-02-27T10:14:11.646Z",
          "from" : "${brand.name}",
          "id" : "defa41de80f0974ef06f78f6058d1424"
      }
  ]
}`,
        },
        messagesGet: {
            requestExample: `$ curl -s -H 'x-api-key: {api_key}' ${brand.apiUrl}messages?limit=4`,
            responseExample: `{
  "nextPageUri" : null,
  "previousPageUri" : null,
  "status" : "OK",
  "messages" : [
     {
        "to" : "61400000003",
        "content" : "Hello, World!",
        "from" : "${brand.name}",
        "uri" : "/2019-01-23/messages/3ca557e8c5d196097e7d008165dac01c",
        "type" : "sms",
        "id" : "3ca557e8c5d196097e7d008165dac01c",
        "receipts" : {
           "undelivered" : "2019-01-23T10:26:57.000Z",
           "simulated" : "2019-01-23T10:26:57.000Z"
        },
        "created" : "2019-01-23T10:26:57.000Z"
     },
     {
        "created" : "2019-01-23T10:15:09.000Z",
        "receipts" : {
           "simulated" : "2019-01-23T10:15:09.000Z",
           "rejected" : "2019-01-23T10:15:09.000Z"
        },
        "from" : "${brand.name}",
        "content" : "Hello, World!",
        "to" : "61400000002",
        "id" : "1da0bdeaf5229f895a558c3b0be13128",
        "type" : "sms",
        "uri" : "/2019-01-23/messages/1da0bdeaf5229f895a558c3b0be13128"
     },
     {
        "type" : "sms",
        "uri" : "/2019-01-23/messages/74af2a249d748fe4b2f3b85e12cb4148",
        "id" : "74af2a249d748fe4b2f3b85e12cb4148",
        "content" : "Hello, World!",
        "to" : "61400000001",
        "from" : "${brand.name}",
        "receipts" : {
           "expired" : "2019-01-23T10:14:36.000Z",
           "simulated" : "2019-01-23T10:14:36.000Z"
        },
        "created" : "2019-01-23T10:14:36.000Z"
     },
     {
        "to" : "61400000000",
        "content" : "Hello, World!",
        "from" : "${brand.name}",
        "type" : "sms",
        "uri" : "/2019-01-23/messages/defa41de80f0974ef06f78f6058d1424",
        "id" : "defa41de80f0974ef06f78f6058d1424",
        "receipts" : {
           "delivered" : "2019-01-23T10:14:12.000Z",
           "simulated" : "2019-01-23T10:14:12.000Z"
        },
        "created" : "2019-01-23T10:14:12.000Z"
     }
  ],
  "uri" : "/2019-01-23/messages"
}`
        },
        messagesInboundPostBack: {
          responseExample: `{
   "messages" : [
      {
         "id" : "01F9D8YBDP3TD611FZB1WJ1RD9",
         "content" : "Test",
         "from" : "61480092227",
         "type" : "sms",
         "to" : "61480092227",
         "created" : "2021-06-30T01:36:14.813Z",
         // files array is included for messages with type 'mms'.
         "files" : [
           {
              "id" : "4710fd2fa276d2b29f42135e8e8d759bcf1348743d62bdf6b6f3abdfa53aca1d",
              "filename" : "Attch124.smil",
              "url" : ""
           },
         ]
      }
   ]
}`,
        },
        messagesPostBack: {
            responseExample: `{
  "uri" : "/2019-01-23/messages/defa41de80f0974ef06f78f6058d1424",
  "messages" : [
     {
        "uri" : "/2019-01-23/messages/defa41de80f0974ef06f78f6058d1424",
        "created" : "2019-01-23T10:14:12.000Z",
        "receipts" : {
           "simulated" : "2019-01-23T10:14:12.000Z",
           "delivered" : "2019-01-23T10:14:12.000Z"
        },
        "to" : "61400000000",
        "from" : "${brand.name}",
        "id" : "defa41de80f0974ef06f78f6058d1424",
        "content" : "Hello, World!",
        "type" : "sms"
     }
  ]
}`
        },
        messagesGetId: {
            requestExample: `$ curl -s -H 'x-api-key: xxxxxx' ${brand.apiUrl}messages/defa41de80f0974ef06f78f6058d1424`,
            responseExample: `{
  "uri" : "/2019-01-23/messages/defa41de80f0974ef06f78f6058d1424",
  "status" : "OK",
  "messages" : [
     {
        "to" : "61400000000",
        "content" : "Hello, World!",
        "from" : "${brand.name}",
        "type" : "sms",
        "uri" : "/2019-01-23/messages/defa41de80f0974ef06f78f6058d1424",
        "id" : "defa41de80f0974ef06f78f6058d1424",
        "receipts" : {
           "delivered" : "2019-01-23T10:14:12.000Z",
           "simulated" : "2019-01-23T10:14:12.000Z"
        },
        "created" : "2019-01-23T10:14:12.000Z"
     }
  ]
}`
        },
    },
    msns: {
      // organisationGet: {
      //    requestExample: `$ curl -s -H 'x-api-key: xxxxxx' '${brand.apiUrl}organisations/dd8f595b3028eeff...' | json_pp`,
      //    responseExample: `{
      //       "id": "dd8f595b3028eeff...",
      //       "efxId": "Y5EAHOIXSF",
      //       "name": CLIENT_LOCATION,
      //       "features": ["bookings.sms", "campaigns.pstn", "campaigns.sms", "chat.email"],
      //       "mfa": {
      //          "sms": { "maxAge": 86400 }
      //        },
      //        "settings": {},
      //        "principal": true,
      //        "owners": ["e635c7ba5410b76f7ac2e93346634c96c789c4661527742cb4d31706727b2f8a"],
      //        "status":"OK"\
      //    \n}`,
      // },
      organisationMsnsGet: {
         requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/msns`,
         responseExample: `{
  "msns": {
     "611480095589": {"alias": "Dev Alias"}
  },
  "status": "Found 1 MSN",
}`,
      },
      msnsGet: {
         requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/msns/{msn_id}`,
         responseExample: `{
"alias": "John",
"forwarding": {
  "61401356325": {
    "enabled": false,
    "type": "SMS"
  },
  "${brand.chat.name}": {
    "enabled": true,
    "type": "Console"
  },
  "john@email.com": {
    "enabled": false,
    "historyMessages": "2",
    "type": "Email"
  },
},
"voice": {
  "6140865436": {
    "enabled": true,
    "notifySms": true
  }
},
"replies": {
  "AgentsOffline": {
    "message": "Agent Offline",
    "enabled": true
  },
  "AgentsOnline": {
    "message": "Agent Online",
    "enabled": false
  }
},
"status": "MSN Found"
}`,
      },
      msnsPost: {
         requestExample: `$ curl -s -X POST -H 'x-api-key: xxx' -H 'Content-Type: application/json' -d '{"alias":"New Alias"}' ${brand.apiUrl}organisations/{organisation_id}/msns/61480095589`,
         responseExample: `{
  "status": "Updated"
}`,
      },
      msnsVoiceDelete: {
         requestExample: `$ curl -s -X DELETE -H 'x-api-key: xxx' ${brand.apiUrl}organisations/{organisation_id}/msns/61480095589/voice/61401345687`,
         responseExample: `{
  "status": "Deleted"
}`,
      },
      msnsSmsDelete: {
         requestExample: `$ curl -s -X DELETE -H 'x-api-key: xxx' ${brand.apiUrl}organisations/{organisation_id}/msns/61480095589/forwarding/john@email.com.au`,
         responseExample: `{
    "status": "Deleted"
 }`,
      },
    },
    members: {
      membersGet: {
         requestExample: `$ curl -s -H 'x-api-key: xxxxxx' ${brand.apiUrl}organisations/{organisation_id}/members`,
         responseExample: `{
  "members": [
     {
        "email": "john.doe@email.com",
        "email2Sms": {},
        "id": "4066c8719c3a7ea81f1d6ed4913020051d740a6dccabc01a46930ed58b7af481",
        "role": "admin",
     },
  ],
  "status": "Found 1 Member",
}`,
      },
      memberDelete: {
         requestExample: `$ curl -s -X DELETE -H 'x-api-key:{api_key}' ${brand.apiUrl}organisations/{organisation_id}/members/{member_id}`,
         responseExample: `{
  "status": "Deleted"
}`,
      },
      memberPost: {
         requestExample: `$ curl -X POST -H 'x-api-key: {api_key}' -H 'Content-Type: application/json' -d '{"email":"john.doe@email.com","role":"agent"}' ${brand.apiUrl}organisations/{organisation_id}/members`,
         responseExample: `{
  "id": "d4eaf8ef8bcfa744...",
  "status": "Created"
}`,
      },
      memberPermissionsGet: {
         requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/members/{member_id}/permissions`,
         responseExample: `{
  "permissions": "["chat.console.login", "chat.queue.unified", "chat.sms.initiate"]",
  "status": "OK"
}`
      },
      memberPermissionsPost: {
         requestExample: `$ curl -X POST -H 'x-api-key: {api_key}' -H 'Content-Type: application/json' -d '["chat.web.freetext", "chat.sms.initiate"]' ${brand.apiUrl}2019-01-23/organisations/{organisation_id}/members/{member_id}/permissions`,
         responseExample: `{
  "status": "OK"
}`
      },
    },
    lists: {
      getLists: {
        requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/lists`,
        responseExample: `{
  "lists": [
     {
       "id": "2389f089784c6ce30494588bf942a370f484d31d75edd48370e584a4f07dfd",
       "name": "My First List",
     },
     ...
  ]
}`,
      },
      postList: {
        requestExample: `$ curl -H 'x-api-key: {api_key}' -H 'Content-Type: application/json' -d '{"name": "My List Name"}' ${brand.apiUrl}organisations/{organisation_id}/lists`,
        responseExample: `{
  "id": "32782394782934uishjdf93",
  "status": "created",
}`,
      },
      deleteList: {
        requestExample: `$ curl -X DELETE -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/lists/{list_id}`,
        responseExample: `{
  status: "Deleted"
}`,
      },
      getListDetails: {
        requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/lists/{list_id}/items?offset={offset}&limit={limit}`,
        responseExample: `{
  "items": [
     {
       "key": "example.email@domain.com"
       "created": "2021-07-29T02:32:00.701Z",
       "type": "email",
     },
     {
       "key": "61400000000"
       "created": "2021-07-29T02:32:00.701Z",
       "type": "pstn",
     },
     ...
  ],
  "moreItems": true
}`,
      },
      postListItems: {
        requestExample: `$ curl -X POST -H 'x-api-key: {api_key}' -H 'Content-Type: application/json' -d '{put: [{"type": "pstn", "key": "61400000000"}], delete: [{"type": "email", "key": "test@example.com"}]}' ${brand.apiUrl}organisations/{organisation_id}/lists/{list_id}`,
        responseExample: `{
  deletes: 0,
  puts: 1,
  status: "OK"
}`,
      }
    },
    transcripts: {
       transcriptsGet: {
         requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/transcripts?from=2021-06-03T14:00:00.000Z&to=2021-06-04T13:59:59.999Z`,
         responseExample: `{
  "chats": ["20210603458764_msn_61876543213_61567843219", ...]
}`,
       },
       transcriptGet: {
          requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/transcripts/20210604020043_msn_61876543213_61567843219?no_messages`,
          responseExample: `{
  "id":"20210604020043_msn_61876543213_61567843219",
  "date":"2021-06-04T02:00:43.356Z",
  "messageCounts":{
     "system":1,
     "guest":1
  },
  "users":[
     {
        "id":"msn_61876543213_61567843219",
        "name":"Mobile",
        "type":"guest"
     }
  ],
  "status":"OK"
}`,
       },
       transcriptGetMessages: {
          requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/transcripts/20210604020043_msn_61876543213_61567843219?since=2021-06-05T04:47:59.226Z`,
          responseExample: `{
  "id": "20210604020043_msn_61876543213_61567843219",
  "date": "2021-06-05T04:46:05.426Z",
  "messageCounts": {
    "guest": 1
  },
  "users": [
    {
      "id": "msn_61876543213_61567843219",
      "name": "Mobile",
      "type": "guest"
    }
  ],
  "messages": [
    {
      "user": {
        "id": "msn_61876543213_61567843219",
        "name": "Mobile",
        "type": "guest"
      },
      "from": "61567843219",
      "to": "61876543213",
      "message": "Bonjour ",
      "sentiment": "neutral_10",
      "language": "fr",
      "translation": "Hello ",
      "id": "1622869140957",
      "date": "2021-06-05T04:59:00.957Z"
    }
  ],
  "status": "OK"
}`
       }
    },
    statistics: {
      chatStatistics: {
         requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/stats/chat/sms?month=2021-05-31T14:00:00.000Z&timezone_offset=-600`,
         responseExample: `{
  "stats": {
    "users": {
      "891ebf4b4bd4b76f9a3dc37f0c94db42948d60e008a0aefb28abaadf1a159627": {
        "response": {
          "2021-06-04T14:00:00.000Z": {
            "average": 72
          },
          "2021-06-03T14:00:00.000Z": {
            "average": null
          },
          "2021-06-02T14:00:00.000Z": {
            "average": null
          },
          "2021-06-01T14:00:00.000Z": {
            "average": null
          },
          "2021-05-31T14:00:00.000Z": {
            "average": null
          }
        },
        "wait": {
          "2021-06-04T14:00:00.000Z": {
            "average": 19
          },
          "2021-06-03T14:00:00.000Z": {
            "average": null
          },
          "2021-06-02T14:00:00.000Z": {
            "average": null
          },
          "2021-06-01T14:00:00.000Z": {
            "average": null
          },
          "2021-05-31T14:00:00.000Z": {
            "average": null
          }
        },
        "sessions": {
          "2021-06-04T14:00:00.000Z": {
            "average": 95,
            "count": 1
          },
          "2021-06-03T14:00:00.000Z": {
            "average": null,
            "count": 0
          },
          "2021-06-02T14:00:00.000Z": {
            "average": null,
            "count": 0
          },
          "2021-06-01T14:00:00.000Z": {
            "average": null,
            "count": 0
          },
          "2021-05-31T14:00:00.000Z": {
            "average": null,
            "count": 0
          }
        }
      }
    },
    "ccts": {
      "8a355f0054431c91e44c957100e870b8daf8f4f53b9c132c88544a1cbb5ec83b": {
        "users": {
          "891ebf4b4bd4b76f9a3dc37f0c94db42948d60e008a0aefb28abaadf1a159627": {
            "response": {
              "2021-06-04T14:00:00.000Z": {
                "average": null
              },
              "2021-06-03T14:00:00.000Z": {
                "average": null
              },
              "2021-06-02T14:00:00.000Z": {
                "average": null
              },
              "2021-06-01T14:00:00.000Z": {
                "average": null
              },
              "2021-05-31T14:00:00.000Z": {
                "average": null
              }
            },
            "wait": {
              "2021-06-04T14:00:00.000Z": {
                "average": null
              },
              "2021-06-03T14:00:00.000Z": {
                "average": null
              },
              "2021-06-02T14:00:00.000Z": {
                "average": null
              },
              "2021-06-01T14:00:00.000Z": {
                "average": null
              },
              "2021-05-31T14:00:00.000Z": {
                "average": null
              }
            },
            "sessions": {
              "2021-06-04T14:00:00.000Z": {
                "average": null,
                "count": 0
              },
              "2021-06-03T14:00:00.000Z": {
                "average": null,
                "count": 0
              },
              "2021-06-02T14:00:00.000Z": {
                "average": null,
                "count": 0
              },
              "2021-06-01T14:00:00.000Z": {
                "average": null,
                "count": 0
              },
              "2021-05-31T14:00:00.000Z": {
                "average": null,
                "count": 0
              }
            }
          },
        }
      }
    }
  },
  "status": "OK"
}`,
      },
      smsUsage: {
         requestExample: `$ curl -H 'x-api-key: {api_key}' ${brand.apiUrl}organisations/{organisation_id}/usage/sms/surveys?month=2021-05-31T14:00:00.000Z&timezone_offset=-600`,
         responseExample: `{
  "usage": {
    "surveys": {
      "c4ee72be34977846454f42c109e6eccd95bc269e5d59e6391cbd5d54e407ce08": {
        "messages": {
          "2021-06-04T14:00:00.000Z": 0,
          "2021-06-03T14:00:00.000Z": 0,
          "2021-06-02T14:00:00.000Z": 0,
          "2021-06-01T14:00:00.000Z": 0,
          "2021-05-31T14:00:00.000Z": 0
        },
        "units": {
          "2021-06-04T14:00:00.000Z": 0,
          "2021-06-03T14:00:00.000Z": 0,
          "2021-06-02T14:00:00.000Z": 0,
          "2021-06-01T14:00:00.000Z": 0,
          "2021-05-31T14:00:00.000Z": 0
        }
      },
    }
  },
  "status": "OK"
}`,
      },
    },
    validation: {
      getValidateNumber: {
        requestExample: `curl -s -H 'x-api-key: {apiKey}' -H 'content-type: application/json' ${brand.apiUrl}validations/{validation_id} | json_pp`,
        responseExample: `
{
  "status" : "OK",
  "validations" : [
    {
        "id" : "7e5b070c09608c2e2cd3950fb545d0a86b12b6038bf1362e7d51ce02033620a8",
        "content" : "Connected 505-03 (Vodafone)",   // }
        "receipts" : {                               // } Absent when result
          "connected" : "2021-12-07T12:09:24.214Z"   // } is pending.
        },                                           // }
        "number" : "61433449965",
        "created" : "2021-12-07T12:09:20.277Z"
    }
  ]
}
        `
      },
      postValidateNumber: {
        requestExample: `curl -s -H 'x-api-key: {apiKey}' -H 'content-type: application/json' ${brand.apiUrl}validations -d '{ "number": "61400000000" }' | json_pp`,
        responseExample: `
{
  "id" : "7e5b070c09608c2e2cd3950fb545d0a86b12b6038bf1362e7d51ce02033620a8",
  "status" : "OK"
}
        `
      }
    }
}
