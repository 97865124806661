import { extendedRegExp, unicodeRegExp } from './config/regex';

export const getRequestColour = (request) => {
    switch(request) {
        case 'GET':
            return 'light-blue--text';
        case 'POST':
            return 'light-green--text';
        case 'POSTback':
            return 'amber--text';
        case 'DELETE':
            return 'red--text ligthen-1';
        default:
            return '';
    }
}

export const getRequestArgumentType = (request) => {
    switch(request) {
        case 'GET':
            return 'URL Parameters';
        case 'POST':
            return 'Body';
        default:
            return 'Arguments';
    }
}

export const buildSidebarMenuItems = (docs, currentDocsKey) => {
    const buildSubMenuItems = (apiDescriptionSubMenu, parentRoute) => {
        const apiDescriptionSubMenuKeys = Object.keys(apiDescriptionSubMenu);
        const subMenuRoutes = apiDescriptionSubMenuKeys.filter(key => typeof apiDescriptionSubMenu[key] === 'object');
        const subMenuItems = subMenuRoutes.map(key => ({
            id: apiDescriptionSubMenu[key].id,
            title: apiDescriptionSubMenu[key].title,
            request: apiDescriptionSubMenu[key].request,
            route: `/${currentDocsKey}/${parentRoute}/${apiDescriptionSubMenu[key].id}`
        }));
        return subMenuItems;
    };
    const apiDescriptionParentRoutes = Object.keys(docs.apiDescriptions);
    const sidebarMenuItems = apiDescriptionParentRoutes.map(route => ({
        action: docs.apiSidebar[route].action,
        title: docs.apiDescriptions[route].mainTitle,
        subMenuItems: buildSubMenuItems(docs.apiDescriptions[route], route, currentDocsKey)
    }));
    return sidebarMenuItems;
}

export const smsCount = (originalMessage, showSms=true, max) => {
    let message = originalMessage;
    if (!message) return '';

    let units = 0;

    if (message.match(unicodeRegExp)) {
      let length = message.length * 2;
      units = (length > 140 ? Math.ceil(length / 134) : 1);
    } else {
      let extended = message.match(extendedRegExp);
      let length = message.length + (extended ? extended.length : 0);
      units = (length > 160 ? Math.ceil(length / 153) : 1);
    }

    if (!showSms) return units;

    return `${Array.from(message).length}/${max} characters: SMS ${units} ${units > 2 ? 'units' : 'unit'}`;
};

export const maxChars = (message, units=10) => {
    switch (units) {
        case 0:
        return 0;

        case 1:
        return message.match(unicodeRegExp) ? 70 : 160;

        default:
        return message.match(unicodeRegExp) ? 67 * units : 153 * units;
    }
}

export const restrictSmsInput = (message, units=10) => {
    return smsCount(message, false) <= units ? message : message.substr(0, maxChars(message, units));
}

export const randomHex = len => {
    let maxlen = 8,
        min = Math.pow(16, Math.min(len, maxlen) - 1),
        max = Math.pow(16, Math.min(len, maxlen)) - 1,
        n   = Math.floor(Math.random() * (max - min + 1)) + min,
        r   = n.toString(16);

    while (r.length < len) {
        r = r + randomHex( len - maxlen );
    }

    return r;
};

export const autosize = target => {
    if (!target) return;
    target.style.height = "auto";
    target.style.height = `${ target.scrollHeight + 2 }px`;
}