<template>
    <div>
        <v-row>
            <v-col cols='12'>
                <DocumentationHeader
                    :title='apiDescriptions.mainTitle'
                    :description='apiDescriptions.mainDescription' />
                <template v-for='(definitions, key) in this.apiDefinitions'>
                    <v-card
                        :key='key'>
                        <v-card-text class='card-spacing'>
                            <DocumentationBody
                                :apiDescription='apiDescriptions[key]'
                                :apiDefinitionHeaders='apiDefinitionHeaders'
                                :apiDefinitions='apiDefinitions[key]' />
                            <DocumentationUtilities
                                v-if='apiUtilities && (key in apiUtilities)'
                                :availableUtilities='apiUtilities[key]' />
                            <DocumentationExample
                                v-if="apiExamples && apiExamples[key]"
                                :apiExamples='apiExamples[key]' />
                        </v-card-text>
                    </v-card>
                </template>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DocumentationHeader from '../components/DocumentationHeader.vue'
import DocumentationBody from '../components/DocumentationBody.vue'
import DocumentationExample from '../components/DocumentationExample.vue';
import DocumentationUtilities from '../components/DocumentationUtilities.vue';
import brandEnv from 'brand/env';

export default {
    name: 'DocumentationContent',
    components: {
        DocumentationHeader,
        DocumentationBody,
        DocumentationExample,
        DocumentationUtilities
    },
    data: () => ({
        brandEnv,
        apiDescriptions: {},
        apiDefinitionHeaders: [],
        apiDefinitions: {},
        apiExamples: {},
        apiUtilities: {}
    }),
    props: [
        'docs',
        'currentDocs',
        'currentPage',
        'currentEndPoint',
    ],
    beforeMount() {
        this.setCurrentDocumentation();
    },
    methods: {
        setCurrentDocumentation: function() {
            var currentDocs = this.currentDocs;
            var currentPage = this.currentPage;
            this.apiDescriptions = this.docs[currentDocs].apiDescriptions[currentPage];
            this.apiDefinitionHeaders = this.docs[currentDocs].apiDefinitionHeaders;
            this.apiDefinitions = this.docs[currentDocs].apiDefinitions[currentPage];
            this.apiExamples = this.docs[currentDocs].apiExamples[currentPage];
            this.apiUtilities = this.docs[currentDocs].apiUtilities[currentPage];
        }
    },
    watch: {
        currentDocs: function() {
            this.setCurrentDocumentation();
        },
        currentPage: function() {
            this.setCurrentDocumentation();
        }
    }
}
</script>

<style>
.card-spacing {
    margin-bottom: 10vh;
}
</style>
