<template>
    <v-col cols='12'>
        <v-row>
          <h3 class="mt-3">Examples</h3>
            <v-col cols='12' v-if="apiExamples.requestExample">
                <v-card style='background-color: #4f566b;'>
                    <v-card-text>
                        <h3 class='font-weight-light' style='color: #9fa8b6;'>Curl Request</h3>
                    </v-card-text>
                    <v-divider />
                    <prism-editor

                        class='request-editor'
                        style="overflow-y: scroll"
                        v-model='apiExamples.requestExample'
                        :highlight='highlighter'
                        line-numbers
                        :readonly='true'
                    />
                </v-card>
                <v-divider />
            </v-col>
            <v-col cols='12' v-if="apiExamples.responseExample">
                <v-card style='background-color: #4f566b;'>
                    <v-card-text>
                        <h3 class='font-weight-light' style='color: #9fa8b6;'>JSON Response</h3>
                    </v-card-text>
                    <v-divider />
                    <prism-editor
                        class="request-editor"
                        v-model='apiExamples.responseExample'
                        :highlight='highlighterJson'
                        line-numbers
                        :readonly='true'
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.css';

export default {
    name: 'DocumentationExample',
    components: {
        PrismEditor,
    },
    props: [
      'apiExamples',
    ],
    methods: {
        highlighter(code) {
            return highlight(code, languages.bash);
        },
        highlighterJson(code) {
            return highlight(code, languages.json);
        },
    },
}
</script>

<style>
.request-editor {
    background: #2d2d2d;
    color: #ccc;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
}

.prism-editor__textarea:focus {
    outline: none;
}

.prism-editor-wrapper {
    max-height: 60vh;
}
</style>
