import brand from 'brand/env';

export default {
    authentication: {
        mainTitle: 'Authentication',
        mainDescription: `To access resources on the ${brand.name} API, you will need to be Authenticated. You can choose from one of the Authentication methods listed below. We recommend API Key authentication in most cases. ${brand.company} staff will never ask you for your API Keys or passwords. Do not share your key with anyone.`,
        apiKey: {
            id: 'apiKey',
            title: 'API Key',
            request: null,
            endPoint: null,
            endPointDescription: `API Keys can be created in your ${brand.name} Workspace Channels config. An API Key provides Admin level authenticated access to your workspace resources.`,
            headerDescription: null,
            argumentDescription: null,
            responseDescription: null,
        },
        sessionToken: {
            id: 'sessionToken',
            title: 'Session Token',
            request: null,
            endPoint: null,
            endPointDescription: null,
            headerDescription: null,
            argumentDescription: null,
            responseDescription: null,
        },
        basic: {
            id: 'basic',
            title: 'Basic Auth',
            request: null,
            endPoint: null,
            endPointDescription: null,
            headerDescription: null,
            argumentDescription: null,
            responseDescription: null,
        },
    },
    messages: {
        mainTitle: 'Messages',
        mainDescription: `The messages resource is used to send messages across all the mediums supported by ${brand.name}. It can also be used to retrieve read receipts and message status.`,
        messagesPost: {
            id: 'messages-post',
            title: 'Message',
            request: 'POST',
            endPoint: 'messages',
            endPointDescription: `Send a Message across one of the supported mediums (SMS and Email). The message content can either be set with the "content" property, or the "template" and "placeholder" properties can be used to compose messages using the ${brand.name} Template system.`,
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: 'The body must be either JSON- or URL-encoded.',
            responseDescription: 'Response is always JSON-encoded.',
        },
        messagesGet: {
            id: 'messages-get',
            title: 'Messages',
            request: 'GET',
            endPoint: 'messages?limit={limit}',
            endPointDescription: 'GET is used to retrieve all messages, most recent first. The number of messages returned in a single response is determined by the limit parameter. The nextPageUri and previousPageUri response properties hold the previous/next request URIs for the paginated results.',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded.',
        },
        messagesInboundPostBack: {
          id: 'messages-inbound-postback',
          title: 'Inbound Message',
          request: 'POSTback',
          endPointDescription: `If you have nominated a POSTback URL for your API Key, ${brand.name} will post back inbound message notifications in realtime. For MMS messages, an array of files will be included.`,
          headerDescription: null,
          argumentDescription: null,
          responseDescription: null,
        },
        messagesPostBack: {
            id: 'messages-postback',
            title: 'Delivery Receipt',
            request: 'POSTback',
            endPointDescription: `If you have nominated a POSTback URL for your API Key, ${brand.name} will post back delivery receipts in realtime. Request body is always JSON-encoded.`,
            headerDescription: null,
            argumentDescription: null,
            responseDescription: null,
        },
        messagesGetId: {
            id: 'message-get',
            title: 'Message',
            request: 'GET',
            endPoint: 'messages/{message_id}',
            endPointDescription: 'GET is used to retrieve a single message.',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded.',
        },
    },
    validation: {
        mainTitle: 'Validation',
        mainDescription: 'Validate whether a number is connected and is able to be contacted.',
        postValidateNumber: {
            id: 'validation-post-number',
            title: 'Validation Number',
            request: 'POST',
            endPoint: 'validations',
            endPointDescription: 'Submit a number to validate whether it is connected.',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded'
        },
        getValidateNumber: {
            id: 'validation-get-number',
            title: 'Validation Number',
            request: 'GET',
            endPoint: 'validations/{validation_id}',
            endPointDescription: `GET is used to retrieve a validated number's status`,
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
    },
    msns: {
        mainTitle: 'Virtual Numbers',
        mainDescription: `Virtual Number forwarders and other properties can be configured via API.`,
        organisationMsnsGet: {
            id: 'organisations-msns',
            title: "Virtual Numbers",
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/msns',
            endPointDescription: 'GET is used to retrieve MSNs from an organisation',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        msnsGet: {
            id: 'organisations-msns-config',
            title: 'Virtual Number',
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/msns/{msn_id}',
            endPointDescription: 'GET is used to retrieve the configuration of a specific MSN selected via the params of the request',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        msnsPost: {
            id: 'organisations-msns-post',
            title: 'Virtual Number',
            request: 'POST',
            endPoint: 'organisations/{organisation_id}/msns/{msn_id}',
            endPointDescription: "POST is used to edit the configuration of an organisation's MSN",
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        msnsVoiceDelete: {
            id: 'organisations-msns-voice-delete',
            title: 'Virtual Number Voice Config',
            request: 'DELETE',
            endPoint: 'organisations/{organisation_id}/msns/{msn_id}/voice/{value}',
            endPointDescription: "DELETE is used to delete the voice configuration values of an organisation's MSN",
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        msnsSmsDelete: {
            id: 'organisations-msns-sms-delete',
            title: 'Virtual Number SMS Config',
            request: 'DELETE',
            endPoint: 'organisations/{organisation_id}/msns/{msn_id}/forwarding/{value}',
            endPointDescription: "DELETE is used to delete the SMS configuration values of an organisation's MSN",
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
    },
    members: {
      mainTitle: 'Members',
      mainDescription: `The Member resource represents a ${brand.name} users relationship with your Workspace. The Member endpoint can be used to CRUD Members, and edit permissions.`,
      membersGet: {
          id: 'organisations-members-get',
          title: 'Members',
          request: 'GET',
          endPoint: 'organisations/{organisation_id}/members',
          endPointDescription: 'GET is used to retrieve all users of an organisation',
          headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
          argumentDescription: null,
          responseDescription: 'Response is always JSON-encoded',
      },
      memberDelete: {
          id: 'organisations-members-delete',
          title: 'Member',
          request: 'DELETE',
          endPoint: 'organisations/{organisation_id}/members/{member_id}',
          endPointDescription: 'DELETE is used to remove a user from an organisation',
          headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
          argumentDescription: null,
          responseDescription: 'Response is always JSON-encoded',
      },
      memberPost: {
          id: 'organisations-member-post',
          title: 'Member',
          request: 'POST',
          endPoint: 'organisations/{organisation_id}/members',
          endPointDescription: 'POST is used to add a member to an organisation',
          headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
          argumentDescription: null,
          responseDescription: 'Response is always JSON-encoded',
      },
      memberPermissionsGet: {
          id: 'organisations-member-permissions-get',
          title: "Member Permissions",
          request: 'GET',
          endPoint: 'organisations/{organisation_id}/members/{member_id}/permissions',
          endPointDescription: 'GET is used to retrieve the permissions a member has within the specified organisation',
          headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
          argumentDescription: null,
          responseDescription: 'Response is always JSON-encoded',
      },
      memberPermissionsPost: {
          id: 'organisations-member-permissions-post',
          title: 'Member Permissions',
          request: 'POST',
          endPoint: 'organisations/{organisation_id}/members/{member_id}/permissions',
          endPointDescription: 'POST is used to add permissions to a member within the specified organisation',
          headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
          argumentDescription: null,
          responseDescription: 'Response is always JSON-encoded',
      },
    },
    transcripts: {
        mainTitle: 'Transcripts',
        mainDescription: `Transcripts are generated for all communication passed through ${brand.name}.`,
        transcriptsGet: {
            id: 'transcripts-get',
            title: 'Transcripts',
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/transcripts?from={start_utc_date}&to={end_utc_date}',
            endPointDescription: 'GET is used to retrive transcripts from a specified date',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        transcriptGet: {
            id: 'transcript-get',
            title: 'Transcript',
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/transcripts/{transcript_id}?no_messages',
            endPointDescription: 'GET is used to retrive a single transcript',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        transcriptGetMessages: {
            id: 'transcript-get-messages',
            title: 'Transcript Messages',
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/transcripts/{transcript_id}?since={start_utc_date}',
            endPointDescription: 'GET is used to retrive messages from a transcript onwards from a specified date',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
    },
    lists: {
      mainTitle: 'Guest Lists',
      mainDescription: 'Lists represent contact details in key:value pairs. They are a generic resource, however their main application at this stage is to represent DNC lists. Lists can hold either emails or phone numbers.',
      postList: {
        id: 'list-post',
        title: 'List',
        request: 'POST',
        endPointDescription: 'Create an empty List.',
        headerDescription: '',
        argumentDescription: null,
        responseDescription: 'Response is always JSON-encoded',
      },
      deleteList: {
        id: 'list-delete',
        title: 'List',
        request: 'DELETE',
        endPointDescription: 'Deletes the nominated list, and all its items.',
        headerDescription: '',
        argumentDescription: null,
        responseDescription: 'Response is always JSON-encoded',
      },
      getLists: {
        id: 'list-get',
        title: 'Lists',
        request: 'GET',
        endPointDescription: 'Retrieve array of Lists setup in the workspace.',
        headerDescription: '',
        argumentDescription: null,
        responseDescription: 'Response is always JSON-encoded',
      },
      getListDetails: {
        id: 'list-get-details',
        title: 'List Items',
        request: 'GET',
        endPointDescription: 'Retrieve array of items in a given List.',
        headerDescription: '',
        argumentDescription: null,
        responseDescription: 'Response is always JSON-encoded',
      },
      postListItems: {
        id: 'list-put-details',
        title: 'List Items',
        request: 'POST',
        endPointDescription: 'Create/Delete Items in a List. This endpoint supports bulk creation/deletion of key:value pairs',
        headerDescription: '',
        argumentDescription: null,
        responseDescription: 'Response is always JSON-encoded',
      }
    },
    statistics: {
        mainTitle: 'Statistics',
        mainDescription: `You can retrieve the SMS Usage across features, and Chat Statistics across mediums.`,
        chatStatistics: {
            id: 'statistics-get-chat-stats',
            title: 'Chat Statistics',
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/stats/chat/{chat_type}?month={utc_date}&timezone_offset={date_offset}',
            endPointDescription: 'GET is used to retrive chat statistics from an organisation at a specified date',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        },
        smsUsage: {
            id: 'statistics-get-sms-usage',
            title: 'SMS Usage',
            request: 'GET',
            endPoint: 'organisations/{organisation_id}/usage/sms/{chat_type}?month={utc_date}&timezone_offset={date_offset}',
            endPointDescription: 'GET is used to retrieve SMS Usage from an organisation at a specified date',
            headerDescription: 'A standard authentication header needs to be included with every request. Refer to the Authentication section for details.',
            argumentDescription: null,
            responseDescription: 'Response is always JSON-encoded',
        }
    },
    apiUsage: {
      mainTitle: 'API Conventions',
      mainDescription: `This section documents global standards enforced by the ${brand.name} API`,
      responses: {
        id: 'get-responses',
        title: 'Global Response Code Guidelines',
        request: '',
        endPoint: '',
        endPointDescription: `The ${brand.name} API responds with standard HTTP response codes according to the status of the response.`,
        headerDescription: '',
        argumentDescription: null,
        responseDescription: '',
      },
    },
};
