<template>
    <div :id='apiDescription.id'>
        <ItemHeader
            :title='apiDescription.title'
            :request='apiDescription.request'
            :endPoint='apiDescription.endPoint'
        />
        <ItemSubHeader
            headerTitle='Description'
            :headerDescription='apiDescription.endPointDescription'
        />

        <div class="mt-5">

        </div>
        <h3 v-if="apiDefinitions.headers">Headers</h3>
        <v-simple-table v-if="apiDefinitions.headers" class="mb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Required
                </th>
                <th class="text-left">
                  Example
                </th>
                <th class="text-left">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in apiDefinitions.headers"
                :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <v-chip
                    label
                    outlined
                    v-if="item.required"
                    color="orange">
                    Required
                  </v-chip>
                </td>
                <td style="max-width: 180px;">{{ item.example }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h3 v-if="apiDefinitions.params">URL Paramaters</h3>
        <v-simple-table v-if="apiDefinitions.params" class="mb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Example
                </th>
                <th class="text-left">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in apiDefinitions.params"
                :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.example }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h3 v-if="apiDefinitions.queries">URL Queries</h3>
        <v-simple-table v-if="apiDefinitions.queries" class="mb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Required
                </th>
                <th class="text-left">
                  Example
                </th>
                <th class="text-left">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in apiDefinitions.queries"
                :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <v-chip
                    label
                    outlined
                    v-if="item.required"
                    color="orange">
                    Required
                  </v-chip>
                </td>
                <td>{{ item.example }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h3 v-if="apiDefinitions.body">Body Content</h3>
        <v-simple-table v-if="apiDefinitions.body" class="mb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Required
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Example
                </th>
                <th class="text-left">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in apiDefinitions.body"
                :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <v-chip
                    label
                    outlined
                    v-if="item.required"
                    color="orange">
                    Required
                  </v-chip>
                </td>
                <td>{{ item.type }}</td>
                <td>{{ item.example }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-alert
          v-if="apiDefinitions.notes && apiDefinitions.notes.length"
          type="info"
          outlined>
          <h4 class="mb-2 text--secondary">
            Note
          </h4>
          <div
            v-for="(note, index) in apiDefinitions.notes"
            :key="`${apiDescription.id}-note-${index}`"
            class="text--secondary">
            {{ note }}
          </div>
        </v-alert>

        <h3 v-if="apiDefinitions.responseCodes">Responses</h3>
        <v-simple-table v-if="apiDefinitions.responseCodes" class="mb-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Status
                </th>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in apiDefinitions.responseCodes"
                :key="index">
                <td>{{ item.status }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
    </div>
</template>

<script>
import ItemHeader from '../components/ItemHeader.vue';
import ItemSubHeader from '../components/ItemSubHeader.vue';
import { getRequestArgumentType } from '../utils';

export default {
    name: 'DocumentationBody',
    components: {
        ItemHeader,
        ItemSubHeader,
    },
    methods: {
        getRequestArgumentType,
    },
    props: [
        'apiDescription',
        'apiDefinitionHeaders',
        'apiDefinitions',
    ],
}
</script>
