import brand from 'brand/env';
import { postBackIps } from 'brand/config';
import {
  apiKey,
  sessionToken,
  mfaToken,
} from '../../appText';

export default {
    authentication: {
        apiKey: {
            headers: [
                {
                  name: 'x-api-key',
                  description: `API Key value.`,
                  required: true,
                  example: apiKey,
                },
            ],
        },
        sessionToken: {
          headers: [
            {
              name: 'x-token',
              description: `Session Token for a ${brand.name} Workspace Member.`,
              required: true,
              example: sessionToken,
            },
            {
              name: 'x-mfa',
              description: 'Required if Mult-Factor Authentication is enabled for your Workspace.',
              required: false,
              example: mfaToken,
            }
          ],
        },
        basic: {
            headers: [
              {
                name: 'Authorization',
                description: `The API Key can be base64decoded to provide a user:password combination.`,
                required: true,
                example: 'Basic defa41de80f0974e:f06f78f6058d1424',
              },
            ],
        },
    },
    messages: {
        messagesPost: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            body: [
                {
                  name: 'from',
                  required: true,
                  type: 'string',
                  description: `E.164 formatted phone number OR Alpha-numeric Sender ID OR email address registered in ${brand.name}.  Please note that when sending to Australian numbers you must use a ${brand.name} virtual number or a pre-approved E.164 formatted Australian mobile number or alpha-number Sender ID (see ${brand.portalUrl}#/channels/sender-ids/list).`,
                  example: '61400000000',
                },
                {
                  name: 'to',
                  required: true,
                  type: 'string',
                  description: 'E.164 formatted phone number OR comma-separated list of email addresses.',
                  example: '61400000000',
                },
                {
                  name: 'content',
                  required: true,
                  type: 'string',
                  description: 'The content of the message being sent. This is either the plaintext SMS body, or Email body (HTML or plaintext).',
                  example: 'How are you today?',
                },
                {
                  name: 'template',
                  required: false,
                  type: 'string',
                  description: `The ID of a ${brand.name} Template. This will pull the content of the message from the nominated Template. This overrides the "content" property.`,
                  example: 'defa41de80f0974ef06f78f6058d1424',
                },
                {
                  name: 'fields',
                  required: false,
                  type: 'object',
                  description: `Only relevant if the "template" property is used to set the message content. Set the values of any placeholder fields to be used in the template.`,
                  example: '{ "first-field": "Hello", "secondField": "World" }',
                },
                {
                  name: 'type',
                  required: true,
                  type: 'string',
                  description: 'Type of message being sent. Currently supports "sms" or "email"',
                  example: 'sms',
                },
                {
                  name: 'subject',
                  type: 'string',
                  description: 'EMAIL ONLY - The Subject of an email.',
                  example: 'Hello, World!',
                },
                {
                  name: 'cc',
                  type: 'string',
                  description: 'EMAIL ONLY - The CC recipient of an email. Supports a comma-separated list of email addresses.',
                  example: 'abc@example.com',
                },
                {
                  name: 'bcc',
                  type: 'string',
                  description: 'EMAIL ONLY - The BCC recipient of an email. Supports a comma-separated list of email addresses.',
                  example: 'abc@example.com',
                },
                {
                  name: 'offTheRecord',
                  required: false,
                  type: 'boolean',
                  description: `SMS ONLY - If TRUE, ${brand.name} will only record a hash of this message in Transcripts.`,
                  example: 'true',
                },
                {
                  name: 'simulated',
                  required: false,
                  type: 'boolean',
                  description: `If set to true the message will not be sent outside of the ${brand.name} network. This feature can be used for integration testing. Defaults to 'false'`,
                  example: 'true',
                },
            ],
        },
        messagesGet: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            body: [
              {
                name: 'limit',
                required: false,
                type: 'number',
                description: 'Desired maximum number of messages to be returned in response. The limit you supply may be automatically reduced by the system if necessary. Automatically determined safe default will be applied if parameter is absent.',
                example: '4',
              },
            ]
        },
        messagesPostBack: {
          notes: [...postBackIps]
        },
        messagesInboundPostBack: {
          notes: [...postBackIps]
        },
        messagesGetId: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'messageId',
                required: true,
                type: 'string',
                description: 'The last component of the URL path is the "messageId" parameter',
                example: 'defa41de80f0974ef06f78f6058d1424',
              },
            ],
        },
    },
    msns: {
        // organisationGet: {
        //     headers: [],
        //     body: [
        //         [
        //             'organisationId',
        //             'Yes.',
        //             'The last component of the URL path is the "organisationId" parameter',
        //             'dd8f595b3028eeffb848cfbedaaee03479900231c0fe863e5dcaadf2fe2c978f',
        //         ]
        //     ],
        // },
        organisationMsnsGet: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The last component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
            ],
        },
        msnsGet: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'msnId',
                required: true,
                type: 'string',
                description: 'The last component of the URL path is the "msnId" parameter. An E.164 encoded phone number.',
                example: '61400000000',
              },
            ]
        },
        msnsPost: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'msnId',
                required: true,
                type: 'string',
                description: 'The last component of the URL path is the "msnId" parameter. An E.164 encoded phone number.',
                example: '61400000000',
              },
            ],
            body: [
              {
                name: 'alias',
                required: false,
                type: 'string',
                description: `The display name used for this Virtual Number in the ${brand.name} Portal`,
                example: 'Marketing Number',
              },
            ],
        },
        msnsVoiceDelete: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'msnId',
                required: true,
                type: 'string',
                description: 'The second component of the URL path is the "msnId" parameter. An E.164 encoded phone number.',
                example: '61400000000',
              },
              {
                name: 'value',
                required: true,
                type: 'string',
                description: 'The last component of the URL path is the "value" parameter (i.e the value to be deleted)',
                example: '',
              },
            ]
        },
        msnsSmsDelete: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'msnId',
                required: true,
                type: 'string',
                description: 'The second component of the URL path is the "msnId" parameter. An E.164 encoded phone number.',
                example: '61400000000',
              },
              {
                name: 'value',
                required: true,
                type: 'string',
                description: 'The last component of the URL path is the "value" parameter (i.e the value to be deleted)',
                example: '',
              },
            ]
        },
    },
    members: {
      membersGet: {
          headers: [
            {
              name: '--- Authentication Required ---',
              description: '',
              required: true,
              example: 'See the Authentication section for details on available authentication methods.',
            },
          ],
          params: [
            {
              name: 'organisationId',
              required: true,
              type: 'string',
              description: 'The first component of the URL path is the "organisationId" parameter',
              example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
            },
          ],
      },
      memberDelete: {
          headers: [
            {
              name: '--- Authentication Required ---',
              description: '',
              required: true,
              example: 'See the Authentication section for details on available authentication methods.',
            },
          ],
          params: [
            {
              name: 'organisationId',
              required: true,
              type: 'string',
              description: 'The first component of the URL path is the "organisationId" parameter',
              example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
            },
            {
              name: 'userId',
              required: true,
              type: 'string',
              description: 'The last component of the URL path is the "userId" parameter.',
              example: 'a497de6fa1e9c886f286bab60da07717be3b18f60083b087e035feeaba5145af',
            },
          ],
      },
      memberPost: {
          headers: [
            {
              name: '--- Authentication Required ---',
              description: '',
              required: true,
              example: 'See the Authentication section for details on available authentication methods.',
            },
          ],
          params: [
            {
              name: 'organisationId',
              required: true,
              type: 'string',
              description: 'The first component of the URL path is the "organisationId" parameter',
              example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
            },
          ],
          body: [
            {
              name: 'email',
              required: true,
              type: 'string',
              description: 'Email address of the new member.',
              example: 'john.doe@domain.com',
            },
            {
              name: 'role',
              required: true,
              type: 'string',
              description: 'The role of the new member. Currently supports "agent", "admin"',
              example: 'agent',
            },
          ],
      },
      memberPermissionsGet: {
          headers: [
            {
              name: '--- Authentication Required ---',
              description: '',
              required: true,
              example: 'See the Authentication section for details on available authentication methods.',
            },
          ],
          params: [
            {
              name: 'organisationId',
              required: true,
              type: 'string',
              description: 'The first component of the URL path is the "organisationId" parameter',
              example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
            },
            {
              name: 'userId',
              required: true,
              type: 'string',
              description: 'The last component of the URL path is the "userId" parameter.',
              example: 'a497de6fa1e9c886f286bab60da07717be3b18f60083b087e035feeaba5145af',
            },
          ],
      },
      memberPermissionsPost: {
          headers: [
            {
              name: '--- Authentication Required ---',
              description: '',
              required: true,
              example: 'See the Authentication section for details on available authentication methods.',
            },
          ],
          params: [
            {
              name: 'organisationId',
              required: true,
              type: 'string',
              description: 'The first component of the URL path is the "organisationId" parameter',
              example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
            },
            {
              name: 'userId',
              required: true,
              type: 'string',
              description: 'The last component of the URL path is the "userId" parameter.',
              example: 'a497de6fa1e9c886f286bab60da07717be3b18f60083b087e035feeaba5145af',
            },
          ],
          body: [
            {
              name: 'permissions',
              required: true,
              type: 'array',
              description: 'An array of permissions to be set. Post an empty array to clear member permissions.',
              example: '["chat.web.freetext","chat.sms.initiate"]',
            },
          ],
      },
    },
    transcripts: {
        transcriptsGet: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'from',
                required: true,
                type: 'string',
                description: 'Start of the date range to fetch as a UTC timestamp',
                example: '2021-06-04T13:59:59.999Z',
              },
              {
                name: 'to',
                required: true,
                type: 'string',
                description: 'End of the date range to fetch as a UTC timestamp',
                example: '2021-06-04T13:59:59.999Z',
              },
            ],
        },
        transcriptGet: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'transcriptId',
                required: true,
                type: 'string',
                description: 'The ID of the transcript to fetch',
                example: '20210604020043_msn_61876543213_61567843219',
              },
            ],
            queries: [
              {
                name: 'no_messages',
                description: "If provided, individual messages are not returned in the body.",
              },
            ],
        },
        transcriptGetMessages: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'transcriptId',
                required: true,
                type: 'string',
                description: 'The ID of the transcript to fetch',
                example: '20210604020043_msn_61876543213_61567843219',
              },
              {
                name: 'since',
                required: true,
                type: 'string',
                description: 'The earliest UTC date from which to retrieve messages for this transcript.',
                example: '2021-05-28T05:24:13.395Z',
              },
            ],
        }
    },
    lists: {
      postList: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'organisationId',
            required: true,
            type: 'string',
            description: 'The ID of the Workspace being queried against.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
        ],
        body: [
          {
            name: 'name',
            required: true,
            type: 'string',
            description: 'Name of the list to be created.',
            example: ``,
          },
        ]
      },
      deleteList: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'organisationId',
            required: true,
            type: 'string',
            description: 'The ID of the Workspace being queried against.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
          {
            name: 'listId',
            required: true,
            type: 'string',
            description: 'The ID of the specific List being deleted.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
        ],
      },
      getLists: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'organisationId',
            required: true,
            type: 'string',
            description: 'The ID of the Workspace being queried against.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
        ],
      },
      getListDetails: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'organisationId',
            required: true,
            type: 'string',
            description: 'The ID of the Workspace being queried against.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
          {
            name: 'listId',
            required: true,
            type: 'string',
            description: 'The ID of the specific List being fetched.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
        ],
        queries: [
          {
            name: 'offset',
            required: true,
            type: 'string',
            description: 'Used for pagination. Sets the initial list item to retrieve in this batch.',
            example: '40',
          },
          {
            name: 'limit',
            required: true,
            type: 'string',
            description: 'Used for pagination. Sets the count of list items to retrieve in this batch.',
            example: '20',
          },
        ],
      },
      postListItems: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'organisationId',
            required: true,
            type: 'string',
            description: 'The ID of the Workspace being queried against.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
          {
            name: 'listId',
            required: true,
            type: 'string',
            description: 'The ID of the specific List to add items to.',
            example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
          },
        ],
        body: [
          {
            name: 'put',
            required: false,
            type: 'object',
            description: 'Container object for items to be added to the list. Can be used alongside "delete" (see below) for mass updates',
            example: ``,
          },
          {
            name: 'delete',
            required: false,
            type: 'object',
            description: 'Container object for items to be deleted from the list. Can be used alongside "put" (see above) for mass updates',
            example: ``,
          },
        ],
      }
    },
    statistics: {
        chatStatistics: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'chatType',
                required: true,
                type: 'string',
                description: 'The chat method to be retrieved. Currently accepts "sms", "web"',
                example: 'web',
              },
            ],
            queries: [
              {
                name: 'month',
                required: true,
                type: 'string',
                description: 'The UTC date corresponding to the start of the month in the local timezone.',
                example: '2021-04-30T14:00:00.000Z',
              },
              {
                name: 'timezone_offset',
                required: true,
                type: 'string',
                description: 'The UTC offset in minutes for the local timezone',
                example: '-600',
              },
            ],
        },
        smsUsage: {
            headers: [
              {
                name: '--- Authentication Required ---',
                description: '',
                required: true,
                example: 'See the Authentication section for details on available authentication methods.',
              },
            ],
            params: [
              {
                name: 'organisationId',
                required: true,
                type: 'string',
                description: 'The first component of the URL path is the "organisationId" parameter',
                example: 'dd8f595b3028eeffb848cfbedacee03479900231c0fe863e5dcaadf2fe2c978f',
              },
              {
                name: 'usageType',
                required: true,
                type: 'string',
                description: 'The usage type to be retrieved. Currently accepts "api", "console", "email", "express", "forwarding", "surveys"',
                example: 'console',
              },
            ],
            queries: [
              {
                name: 'month',
                required: true,
                type: 'string',
                description: 'The UTC date corresponding to the start of the month in the local timezone.',
                example: '2021-04-30T14:00:00.000Z',
              },
              {
                name: 'timezone_offset',
                required: true,
                type: 'string',
                description: 'The UTC offset in minutes for the local timezone',
                example: '-600',
              },
            ],
        }
    },
    validation: {
      postValidateNumber: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'number',
            description: 'Number to be validated',
            required: true,
            example: '61400000000'
          }
        ]
      },
      getValidateNumber: {
        headers: [
          {
            name: '--- Authentication Required ---',
            description: '',
            required: true,
            example: 'See the Authentication section for details on available authentication methods.',
          },
        ],
        params: [
          {
            name: 'validation_id',
            required: true,
            type: 'string',
            description: 'The id of a previous number validation request.',
            example: '7e5b070c09608c2e2cd3950fb545d0a86b12b6038bf1362e7d51ce02033620a8'
          }
        ]
      },
    },
    apiUsage: {
      responses: {
        responseCodes: [
          {
            status: 200,
            name: 'OK',
            description: 'Indicates that the request has succeeded.',
          },
          {
            status: 201,
            name: 'Created',
            description: 'Indicates that the request has succeeded and a new resource has been created as a result.',
          },
          {
            status: 400,
            name: 'Bad Request',
            description: 'Indicates that the request has not succeeded because it was invalid.  For example, required values were missing or values failed validation.',
          },
          {
            status: 401,
            name: 'Unauthorized',
            description: 'Indicates that the request has not succeeded because credentials are invalid.',
          },
          {
            status: 403,
            name: 'Forbidden',
            description: 'Indicates that the request has not succeeded because authenticated entity is not allowed to perform the request.',
          },
          {
            status: 404,
            name: 'Not Found',
            description: 'Indicates that the request has not succeeded because the requested resource does not exist.',
          },
          {
            status: 409,
            name: 'Conflict',
            description: 'Indicates that the request has not succeeded because the supplied values conflicted with existing resources.',
          },
          {
            status: 429,
            name: 'Too Many Requests',
            description: 'Indicates that the request has not succeeded because the account has had too many requests.',
          },
          {
            status: 500,
            name: 'Internal Server Error',
            description: 'Indicates that the request has not succeeded because there was an unexpected server error.',
          },
          {
            status: 503,
            name: 'Service Unavailable',
            description: 'Indicates that the request has not succeeded because there has been a temporary server error.',
          },
        ],
      },
    },
}
